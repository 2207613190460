import React from 'react'
import PropTypes from 'prop-types'
import IO from 'components/io'
import { Text } from './paragraph.css'

const Paragraph = ({ children, maxWidth = 80, fontSize = 1.9, fontSizeMobile = 1.8, contrastText, hasEffect = false }) => {
    return hasEffect ? (
        <IO>
            {({ hasBeenVisible }) => (
                <Text maxWidth={maxWidth} contrastText={contrastText} fontSize={fontSize} fontSizeMobile={fontSizeMobile} hasBeenVisible={hasBeenVisible}>
                    {children}
                </Text>
            )}
        </IO>
    ) : (
        <Text maxWidth={maxWidth} contrastText={contrastText} fontSize={fontSize} fontSizeMobile={fontSizeMobile} hasBeenVisible={true}>
            {children}
        </Text>
    )
}

Paragraph.propTypes = {
    children: PropTypes.string.isRequired,
    fontSize: PropTypes.number,
    fontSizeMobile: PropTypes.number,
    maxWidth: PropTypes.number,
    contrastText: PropTypes.bool,
    hasEffect: PropTypes.bool
}

export default Paragraph
