import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Colors from 'constants/colors'

export const Container = styled.div`
    padding: 2rem;
    max-width: 100%;
    text-align: center;

    border-top: ${({ hasBorders, theme }) => () => (hasBorders && theme === 'light' ? `solid 1px ${Colors.grey};` : 'none')};
    border-bottom: ${({ hasBorders, theme }) => () => (hasBorders && theme === 'light' ? `solid 1px ${Colors.grey};` : 'none')};

    background-color: ${({ bgColor }) => () => {
        return bgColor || `${Colors.white}`
    }};

    ${MEDIA.MEDIUM`
        padding: 1.5rem ${({ leftRightPadding }) => () => (leftRightPadding ? `${leftRightPadding}rem` : '10rem')} 2rem;
    `};

    ${MEDIA.SMALL`
        padding: 1.5rem;
    `};

    ${MEDIA.XSMALL`
        padding: 1rem 1.5rem;
    `};

    ${MEDIA.MIN_LARGE`
        padding: 1.5rem ${({ leftRightPadding }) => () => (leftRightPadding ? `${leftRightPadding}rem` : '12rem')} 2rem;
    `};

    ${MEDIA.MIN_XLARGE`
        padding: 1.5rem ${({ leftRightPadding }) => () => (leftRightPadding ? `${leftRightPadding}rem` : '25rem')} 2rem;
    `};

    ${MEDIA.MIN_XXLARGE`
        padding: 1.5rem ${({ leftRightPadding }) => () => (leftRightPadding ? `${leftRightPadding}rem` : '35rem')} 2rem;
    `};
`
