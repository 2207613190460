import React from 'react'
import PropTypes from 'prop-types'
import { Buttons } from './social-buttons.css'
import SocialButton from 'components/social-buttons/social-button'

const SocialButtons = ({ socialNetworks }) => (
    <Buttons>
        <SocialButton type="facebook" path={`https://www.facebook.com/${socialNetworks.facebook}`} />
        <SocialButton type="twitter" path={`https://twitter.com/${socialNetworks.twitter}`} />
        <SocialButton type="instagram" path={`https://www.linkedin.com/company/${socialNetworks.instagram}`} />
    </Buttons>
)

SocialButtons.propTypes = {
    socialNetworks: PropTypes.object.isRequired
}

export default SocialButtons
