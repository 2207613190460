import styled from 'styled-components'
import Theme from 'constants/theme'

export const Container = styled.footer`
    left: 0;
    bottom: 0;
    background-color: ${Theme.light.palette.background.tertiary};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`
