import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './copyright.css'

const Copyright = ({ text }) => <Container>{text}</Container>

Copyright.propTypes = {
    text: PropTypes.string.isRequired
}

export default Copyright
