import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Theme from 'constants/theme'

export const Text = styled.p`
    margin: 3rem auto;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: ${({ fontSize }) => () => `${fontSize}rem`};
    line-height: 1.8;
    opacity: ${({ hasBeenVisible }) => () => (hasBeenVisible ? 1 : 0)};
    -webkit-transition: opacity 0.8s ease-in-out 0.15s;
    -moz-transition: opacity 0.8s ease-in-out 0.15s;
    -ms-transition: opacity 0.8s ease-in-out 0.15s;
    -o-transition: opacity 0.8s ease-in-out 0.15s;
    transition: opacity 0.8s ease-in-out 0.15s;

    color: ${({ contrastText }) => () => (contrastText ? Theme.light.palette.text.alternative : Theme.light.palette.text.primary)};

    max-width: ${({ maxWidth }) => () => `${maxWidth}%`};

    ${MEDIA.MEDIUM`
        max-width: 90%;
        font-size: ${({ fontSizeMobile }) => () => `${fontSizeMobile || 1.8}rem`};
    `};

    ${MEDIA.SMALL`
        max-width: 95%;
        font-size: ${({ fontSizeMobile }) => () => `${fontSizeMobile || 1.8}rem`};
    `};

    ${MEDIA.XSMALL`
        max-width: 98%;
        font-size: ${({ fontSizeMobile }) => () => `${fontSizeMobile || 1.7}rem`};
    `};
`
