import styled from 'styled-components'
import Colors from 'constants/colors'

export const Main = styled.main`
    min-height: calc(100vh - 132px);
    padding-top: 60px;
`

export const MainWrapper = styled.div`
    background-color: ${Colors.white};
`
