import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Theme from 'constants/theme'

export const Button = styled.a`
    fill: ${Theme.light.palette.icon.secondary};
    margin: 0 15px;

    ${MEDIA.MIN_MEDIUM`
        &:hover {
                fill: ${Theme.light.palette.icon.primary};
            }
        `}
`
