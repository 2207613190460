import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './footer.css'
import Copyright from 'components/copyright'
import SocialButtons from 'components/social-buttons'

const Footer = ({ text, socialNetworks }) => (
    <Container>
        <Copyright text={text} />
        <SocialButtons socialNetworks={socialNetworks} />
    </Container>
)

Footer.propTypes = {
    text: PropTypes.string.isRequired,
    socialNetworks: PropTypes.object.isRequired
}

export default Footer
