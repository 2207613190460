import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Theme from 'constants/theme'
import Colors from 'constants/colors'

export const Container = styled.span`
    right: 2rem;
    position: fixed;

    nav {
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            position: absolute;
            left: 50%;
            top: 170px;
            transition: all 0.3s ease-in-out;
            transform: translateX(-50%);

            li {
                transform: translateY(50px);
                opacity: 0;

                a {
                    display: block;
                    font-size: 2em;
                    text-decoration: none;
                    padding: 14px 0;
                    text-align: center;
                    color: ${Colors.whiteDark};
                    font-weight: bold;
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }

    .toggle-btn {
        display: block;
        position: relative;
        z-index: 10;
        cursor: pointer;

        .bar {
            width: 30px;
            height: 2px;
            margin: 7px auto;
            background-color: ${Theme.light.palette.icon.secondary};
            transition: all 0.3s ease-in-out;

            &:nth-child(2) {
                width: 20px;
            }
        }
    }

    #toggle:checked {
        & ~ nav {
            opacity: 1;
            visibility: visible;

            ul {
                top: 70px;
                width: 100%;

                li {
                    transform: translateY(0px);
                    opacity: 1;

                    &:nth-child(1) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.1s;
                    }

                    &:nth-child(2) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.2s;
                    }

                    &:nth-child(3) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.3s;
                    }

                    &:nth-child(4) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.4s;
                    }

                    &:nth-child(5) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.5s;
                    }

                    &:nth-child(6) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.6s;
                    }

                    &:nth-child(7) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.7s;
                    }

                    &:nth-child(8) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.8s;
                    }

                    &:nth-child(9) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) 0.9s;
                    }
                }
            }
        }

        & + label.toggle-btn {
            .bar {
                background-color: ${Theme.light.palette.icon.alternative2};

                &:nth-child(2) {
                    transform: translateX(50px);
                    opacity: 0;
                }

                &:nth-child(1) {
                    transform: translateY(10px) rotate(45deg);
                }

                &:nth-child(3) {
                    transform: translateY(-8px) rotate(-45deg);
                }
            }
        }
    }

    ${MEDIA.MIN_MEDIUM`
        display: none
    `};
`

export const Link = styled.a`
    display: flex;
    align-items: center;
`
