import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Head from 'components/head'
import Header from 'components/header'
import { Main, MainWrapper } from './layout.css'
import Footer from 'components/footer'
import GlobalStyle from 'global.css.js'

const Layout = ({ data, children, seo }) => (
    <MainWrapper id="mainWrapper">
        <GlobalStyle />
        <Head seo={seo} />
        <Header title={data.site.siteMetadata.siteTitle} navItems={seo?.navItems} />
        <Main>{children}</Main>
        <Footer text={data.site.siteMetadata.footerText} socialNetworks={data.site.siteMetadata.social} />
    </MainWrapper>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired,
    seo: PropTypes.object
}

const LayoutWithQuery = props => (
    <StaticQuery
        query={graphql`
            query LayoutQuery {
                site {
                    siteMetadata {
                        siteTitle
                        footerText
                        social {
                            facebook
                            twitter
                            instagram
                        }
                    }
                }
            }
        `}
        render={data => <Layout data={data} {...props} />}
    />
)

LayoutWithQuery.propTypes = {
    children: PropTypes.node.isRequired
}

export default LayoutWithQuery
