import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './box.css'
import Wave1Svg from './../../images/wave1.svg'

const Box = ({ children, bgColor = '#FFFFFF', leftRightPadding, hasBorders = false, hasBorderWaveTop = false, hasBorderWaveBottom = false, borderWaveRevert = false }) => (
    <React.Fragment>
        {hasBorderWaveTop && <Wave1Svg style={{ height: 100, width: '100%', marginBottom: '-40px', fill: bgColor, transform: borderWaveRevert ? 'scaleX(-1)' : '' }} />}
        <Container bgColor={bgColor} leftRightPadding={leftRightPadding} hasBorders={hasBorders}>
            {children}
        </Container>
        {hasBorderWaveBottom && <Wave1Svg style={{ height: 100, width: '100%', marginTop: '-25px', fill: bgColor, transform: `scale(${borderWaveRevert ? -1 : 1}, -1)` }} />}
    </React.Fragment>
)

Box.propTypes = {
    children: PropTypes.node.isRequired,
    bgColor: PropTypes.string,
    leftRightPadding: PropTypes.number,
    hasBorders: PropTypes.bool,
    hasBorderWaveTop: PropTypes.bool,
    hasBorderWaveBottom: PropTypes.bool,
    borderWaveRevert: PropTypes.bool
}

export default Box
