export const white = '#FFFFFF'
export const whiteDark = '#FFFFFFDD'
export const black = '#000000'
export const blackLight = '#20232A'
export const blackLightText = '#1C1E21'
export const orange = '#f1803a'
export const orangeDark = '#CC8D18'
export const grey = '#DADDE1'
export const greyDark = '#2D3748'
export const redDark = '#CC342D'
export const blue = '#337ab7'
export const blueDark = '#225784'

export default {
    white,
    whiteDark,
    black,
    blackLight,
    blackLightText,
    orange,
    orangeDark,
    grey,
    greyDark,
    redDark,
    blue,
    blueDark
}
