import styled from 'styled-components'
// import MEDIA from 'helpers/mediaTemplates'
import { XLARGE } from 'constants/breakpoints'
import Theme from 'constants/theme'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const Container = styled.header`
    position: fixed;
    width: 100%;
    background-color: ${Theme.light.palette.background.tertiary};
    z-index: 5;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 1rem 3rem 1rem 2rem;
    max-width: ${XLARGE}px;
    margin: 0 auto;

    a {
        color: ${Theme.light.palette.text.alternative};
        font-weight: bold;
        transition: color 0.2s ease;
        text-decoration: none;
        border-bottom: solid 2px transparent;
        padding-bottom: 6px;
        margin-top: 6px;
    }
`

export const MainLink = styled.h1`
    font-weight: 400;
    font-size: 2rem;
    margin-left: 15px;
`

export const Anchor = styled(AnchorLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
`

export const Link = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
`
