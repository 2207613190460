import React from 'react'
import PropTypes from 'prop-types'
import { Container, Wrapper, MainLink, Anchor, Link } from './header.css'
import Nav from 'components/header/nav'
import MobileNav from 'components/header/mobile-nav'
import LogoSvg from '../../images/logo.svg'

const path = typeof window !== 'undefined' && window.location.pathname

const Header = ({ title, navItems }) => (
    <Container>
        <Wrapper>
            {path === '/' || path === '/es/' ? (
                <Anchor to={`${path}#mainWrapper`}>
                    <LogoSvg style={{ width: 35, height: 30, marginTop: 8, transform: 'scale(2)' }} />
                    <MainLink>{title}</MainLink>
                </Anchor>
            ) : (
                <Link href="/">
                    <LogoSvg style={{ width: 35, height: 30, marginTop: 8, transform: 'scale(2)' }} />
                    <MainLink>{title}</MainLink>
                </Link>
            )}
            <MobileNav items={navItems} />
            <Nav items={navItems} />
        </Wrapper>
    </Container>
)

Header.propTypes = {
    title: PropTypes.string.isRequired,
    navItems: PropTypes.array.isRequired
}

export default Header
