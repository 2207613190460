import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './social-button.css'
import FacebookSvg from '../../images/social/facebook.svg'
import TwitterSvg from '../../images/social/twitter.svg'
import InstagramSvg from '../../images/social/instagram.svg'

const SocialButton = ({ type, path }) => {
    if (type === 'facebook') {
        return (
            <Button href={path} target="_blank" rel="noopener noreferrer">
                <FacebookSvg style={{ width: 30 }} />
            </Button>
        )
    } else if (type === 'twitter') {
        return (
            <Button href={path} target="_blank" rel="noopener noreferrer">
                <TwitterSvg style={{ width: 30 }} />
            </Button>
        )
    } else if (type === 'instagram') {
        return (
            <Button href={path} target="_blank" rel="noopener noreferrer">
                <InstagramSvg style={{ width: 30 }} />
            </Button>
        )
    } else {
        return null
    }
}

SocialButton.propTypes = {
    type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
}

export default SocialButton
