import React from 'react'
import PropTypes from 'prop-types'
import { Container, Link } from './mobile-nav.css'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const MobileNav = ({ items }) => {
    const [toggle, setToggle] = React.useState(false)

    const toggleMenu = () => setToggle(!toggle)

    return (
        <React.Fragment>
            <Container>
                <input type="checkbox" id="toggle" style={{ display: 'none' }} checked={toggle} onChange={toggleMenu} />
                <label className="toggle-btn" htmlFor="toggle">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </label>

                <nav>
                    <ul>
                        {items?.length
                            ? items.map((item, i) => (
                                  <li key={i}>
                                      {item.type === 'anchor' ? (
                                          <AnchorLink to={`#${item.anchor}`}>{item.title}</AnchorLink>
                                      ) : (
                                          <Link href={item.path} rel="noopener noreferrer">
                                              {item.title}
                                          </Link>
                                      )}
                                  </li>
                              ))
                            : null}
                    </ul>
                </nav>
            </Container>
        </React.Fragment>
    )
}

MobileNav.propTypes = {
    items: PropTypes.array.isRequired
}

export default MobileNav
