import Colors from './colors'

export const palette = {
    light: {
        background: {
            primary: Colors.white,
            secondary: Colors.blackLight,
            tertiary: Colors.blue
        },
        text: {
            primary: Colors.blackLightText,
            secondary: Colors.greyDark,
            tertiary: Colors.orange,
            alternative: Colors.white
        },
        icon: {
            primary: Colors.orange,
            secondary: Colors.white,
            tertiary: Colors.blackLight,
            alternative: Colors.orangeDark,
            alternative2: Colors.redDark
        }
    }
}

export default palette
