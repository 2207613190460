import React from 'react'
import PropTypes from 'prop-types'
import { Container, Link } from './nav.css'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Nav = ({ items }) => (
    <Container>
        <ul>
            {items?.length
                ? items.map((item, i) => (
                      <li key={i}>
                          {item.type === 'anchor' ? (
                              <AnchorLink to={`#${item.anchor}`}>{item.title}</AnchorLink>
                          ) : (
                              <Link href={item.path} rel="noopener noreferrer">
                                  {item.title}
                              </Link>
                          )}
                      </li>
                  ))
                : null}
        </ul>
    </Container>
)

Nav.propTypes = {
    items: PropTypes.array.isRequired
}

export default Nav
