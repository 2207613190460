import styled from 'styled-components'
import Theme from 'constants/theme'
import MEDIA from 'helpers/mediaTemplates'

export const Container = styled.span`
    color: ${Theme.light.palette.text.alternative};
    line-height: 1.4;

    ${MEDIA.SMALL`
        display: none;
    `}
`
