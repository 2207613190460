import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Theme from 'constants/theme'

export const Container = styled.nav`
    ul {
        align-items: center;
        display: flex;
        list-style: none;
        padding: 0;

        li {
            font-size: 1.6rem;

            & + li {
                margin-left: 2rem;
            }

            a {
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -moz-osx-font-smoothing: grayscale;
                position: relative;
                overflow: hidden;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    left: 50%;
                    right: 50%;
                    bottom: 0;
                    background: ${Theme.light.palette.text.alternative};
                    height: 2px;
                    -webkit-transition-property: left right;
                    transition-property: left right;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }

                ${MEDIA.MIN_MEDIUM`
                    &:hover:before {
                        left: 0;
                        right: 0;
                    }
                `}
            }
        }

        ${MEDIA.MEDIUM`
            display: none
        `};
    }
`

export const Link = styled.a`
    display: flex;
    align-items: center;
`
