import { createGlobalStyle } from 'styled-components'
import { orange } from 'constants/colors'
import transitions from './transitions.css'

export default createGlobalStyle`
    ${transitions}

    // @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

    // @font-face {
    //     font-family: 'Roboto';
    //     src: url('fonts/Roboto-Bold.ttf') format('truetype');
    //     font-weight: bold;
    //     font-style: normal;
    // }

    // @font-face {
    //     font-family: 'Roboto';
    //     src: url('fonts/Roboto-Regular.ttf') format('truetype');
    //     font-weight: normal;
    //     font-style: normal;
    // }

    // @font-face {
    //     font-family: 'Roboto';
    //     src: url('fonts/Roboto-Light.ttf') format('truetype');
    //     font-weight: light;
    //     font-style: normal;
    // }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    video::-webkit-media-controls-panel {
        backgroudColor: #FFF;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    html {
        font-size: 62.5%;
    }

    body {
        /* Hide scrollbar for Chrome, Safari and Opera */
        ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        // This animation look good when site is loaded but impart in performance (lighthouse)
        // animation: fadeIn linear 1s;
        font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        line-height: 1;
        font-size: 1.6rem;
        color: #000;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-font-feature-settings: "pnum";
        font-feature-settings: "pnum";
        font-variant-numeric: proportional-nums;

        // This code disable the selection of words
        /* user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none; */

        // This code disable the drag of all images
        img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
          }

        // box sizing
        // box-sizing: border-box;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    a {
        color: ${orange};
    }

    pre {
        display: block;
        padding: 2rem;
        margin-top: 4rem;
        overflow: auto;
        font-size: 85%;
        line-height: 1.45;
        border-radius: 5px;
        color: ${orange};
        border: 1px solid #ddd;
        font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
    }

    h2, h3 {
        margin: 2rem 0;
    }
`
