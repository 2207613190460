export const XXLARGE = 1900
export const XLARGE = 1600
export const LARGE = 1200
export const MEDIUM = 992
export const SMALL = 768
export const XSMALL = 376
export const XXSMALL = 240

export default {
    XXLARGE,
    XLARGE,
    LARGE,
    MEDIUM,
    SMALL,
    XSMALL,
    XXSMALL
}
